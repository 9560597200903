button {
    // render empty ballot box
    span.ballot-box::after {
        content: "\2610";
    }

    // render checked ballot box for active button
    &.active span.ballot-box::after {
        content: "\2611";
    }
}
