#edit-pif {
    // add padding to keep content above sticky footer
    padding-bottom: 60px;
    display: flex;
    align-items: flex-start;
}

.edit-pane {
    flex-grow: 1;
    padding: 0 20px;
}

#form-builder {
    background-color: whitesmoke;
}

#form-preview {
    position: sticky;
    top: 0;
    overflow-y: auto;
}

#edit-footer {
    background-color: whitesmoke;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.hidden {
    display: none;
}

// indicate that edit panels are resizable
.gutter {
    cursor: col-resize;
}

// we make bootstrap 3 available to the form preview section
#rendered-pif {
    $icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
    @import "bootstrap-sass/assets/stylesheets/_bootstrap.scss";
}

#rendered-pif .checkbox-inline input[type="checkbox"], #rendered-pif .checkbox input[type="checkbox"], #rendered-pif .radio-inline input[type="radio"], #rendered-pif .radio input[type="radio"] {
    position: relative;
    margin-top: 4px;
    margin-left: -20px;
    top: 1px;
    right: 5px;
}



#rendered-pif .checkbox label, #rendered-pif .radio label {
    min-height: 20px;
    padding-left: 25px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}